import { default as _91post_45slug_93xTQBGZjIZiMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/blog/[post-slug].vue?macro=true";
import { default as indexA8cZown3tvMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/blog/index.vue?macro=true";
import { default as _91year_45slug_93vizhxnlsLrMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/[year-slug].vue?macro=true";
import { default as index1iCKXnXg8PMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/index.vue?macro=true";
import { default as champd_45up_45slam_45down_45rulesq5die7ZAddMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/champd-up-slam-down-rules.vue?macro=true";
import { default as companyTeIkuZ5QSYMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/company.vue?macro=true";
import { default as cmsjXwgvXJSjFMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/debug/cms.vue?macro=true";
import { default as env9FMLxh3JRZMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/debug/env.vue?macro=true";
import { default as _91_46_46_46doc_45path_93bnszFk4RZLMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/docs/[...doc-path].vue?macro=true";
import { default as indexrwyhvVPykZMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/docs/index.vue?macro=true";
import { default as formrJDs16zzl0Meta } from "/home/runner/work/jbg-web/jbg-web/src/pages/form.vue?macro=true";
import { default as _91gametype_45slug_93QZTXMkjI9qMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[gametype-slug].vue?macro=true";
import { default as indexht5dXWRn4dMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/game-type/index.vue?macro=true";
import { default as _91game_45slug_93yFRhSmvz1iMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue?macro=true";
import { default as _91bundle_45slug_93tH9UZhZSF3Meta } from "/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue?macro=true";
import { default as game_45tagshmhqag06r8Meta } from "/home/runner/work/jbg-web/jbg-web/src/pages/games/game-tags.vue?macro=true";
import { default as indexPMdrJIgED0Meta } from "/home/runner/work/jbg-web/jbg-web/src/pages/games/index.vue?macro=true";
import { default as _91pack_45slug_93p9ixEkCerOMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/[pack-slug].vue?macro=true";
import { default as indexhJrPnC150BMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/index.vue?macro=true";
import { default as indexa0tqRycTW0Meta } from "/home/runner/work/jbg-web/jbg-web/src/pages/games/sale/index.vue?macro=true";
import { default as giftmbcvizTL7nMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue?macro=true";
import { default as how_45to_45playVVRUHfbLkKMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/how-to-play.vue?macro=true";
import { default as indexuRmSTRB6icMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/index.vue?macro=true";
import { default as jackbox_45at_45workHO1zr6A9GjMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-at-work.vue?macro=true";
import { default as jackbox_45for_45eventsyMN3nKOJZOMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-events.vue?macro=true";
import { default as jackbox_45for_45familiesyrXQpzlGKaMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-families.vue?macro=true";
import { default as jackbox_45for_45game_45nightZFgT7n3KjzMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-game-night.vue?macro=true";
import { default as jackbox_45for_45streamersq2zK2UarKZMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-streamers.vue?macro=true";
import { default as jackbox_45for_45studentsrPvv31zCxDMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-students.vue?macro=true";
import { default as jobsjn4P50HT8dMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/jobs.vue?macro=true";
import { default as loginpJbw2IDcKUMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/login.vue?macro=true";
import { default as mysterious_45teaserKfS18hE3RAMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/mysterious-teaser.vue?macro=true";
import { default as _91game_93HgEmLZKGK6Meta } from "/home/runner/work/jbg-web/jbg-web/src/pages/play/[game].vue?macro=true";
import { default as indexDapNxU7YqPMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/play/index.vue?macro=true";
import { default as preference_45centerWnl7uDuVQjMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/preference-center.vue?macro=true";
import { default as presskit7jVutcqXBnMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/presskit.vue?macro=true";
import { default as privacy_45policyHVbNabwlNsMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/privacy-policy.vue?macro=true";
import { default as indexmg4DokeYu7Meta } from "/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue?macro=true";
import { default as _91merch_45slug_93zMym9gAgPXMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/shop/merch/[merch-slug].vue?macro=true";
import { default as supportkIOMPEv5DKMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/support.vue?macro=true";
import { default as terms_45of_45serviceHiG6WYOnLgMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/terms-of-service.vue?macro=true";
import { default as the_45jackbox_45megapickerz256JihBVPMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue?macro=true";
import { default as tokenAd3wHcwDQmMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/token.vue?macro=true";
import { default as try_45guysPyCRDgf8eoMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/try-guys.vue?macro=true";
import { default as tvcodeMh3LWR6hhDMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/tvcode.vue?macro=true";
import { default as _91email_93EAgIrHngnSMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword/[email].vue?macro=true";
import { default as indexHkt0MC6CYuMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword/index.vue?macro=true";
import { default as profileP8xjiyB0BqMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/user/profile.vue?macro=true";
import { default as verifyAwZaSzCmO3Meta } from "/home/runner/work/jbg-web/jbg-web/src/pages/user/verify.vue?macro=true";
import { default as xperion6uHwt3Twf7Meta } from "/home/runner/work/jbg-web/jbg-web/src/pages/xperion.vue?macro=true";
import { default as component_45stubYhFd71CncqMeta } from "/home/runner/work/jbg-web/jbg-web/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubYhFd71Cncq } from "/home/runner/work/jbg-web/jbg-web/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "blog-postslug___en",
    path: "/blog/:postslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/[post-slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-postslug___fr",
    path: "/fr/blog/:postslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/[post-slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-postslug___it",
    path: "/it/blog/:postslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/[post-slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-postslug___de",
    path: "/de/blog/:postslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/[post-slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-postslug___es",
    path: "/es/blog/:postslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/[post-slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-postslug___es-XL",
    path: "/es-XL/blog/:postslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/[post-slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-postslug___pt-BR",
    path: "/pt-BR/blog/:postslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/[post-slug].vue").then(m => m.default || m)
  },
  {
    name: "blog___en",
    path: "/blog",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___fr",
    path: "/fr/blog",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___it",
    path: "/it/blog",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___de",
    path: "/de/blog",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___es",
    path: "/es/blog",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___es-XL",
    path: "/es-XL/blog",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___pt-BR",
    path: "/pt-BR/blog",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-year-yearslug___en",
    path: "/blog/year/:yearslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/[year-slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-year-yearslug___fr",
    path: "/fr/blog/year/:yearslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/[year-slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-year-yearslug___it",
    path: "/it/blog/year/:yearslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/[year-slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-year-yearslug___de",
    path: "/de/blog/year/:yearslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/[year-slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-year-yearslug___es",
    path: "/es/blog/year/:yearslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/[year-slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-year-yearslug___es-XL",
    path: "/es-XL/blog/year/:yearslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/[year-slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-year-yearslug___pt-BR",
    path: "/pt-BR/blog/year/:yearslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/[year-slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-year___en",
    path: "/blog/year",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-year___fr",
    path: "/fr/blog/year",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-year___it",
    path: "/it/blog/year",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-year___de",
    path: "/de/blog/year",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-year___es",
    path: "/es/blog/year",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-year___es-XL",
    path: "/es-XL/blog/year",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-year___pt-BR",
    path: "/pt-BR/blog/year",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/index.vue").then(m => m.default || m)
  },
  {
    name: "champd-up-slam-down-rules___en",
    path: "/champd-up-slam-down-rules",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/champd-up-slam-down-rules.vue").then(m => m.default || m)
  },
  {
    name: "champd-up-slam-down-rules___fr",
    path: "/fr/champd-up-slam-down-rules",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/champd-up-slam-down-rules.vue").then(m => m.default || m)
  },
  {
    name: "champd-up-slam-down-rules___it",
    path: "/it/champd-up-slam-down-rules",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/champd-up-slam-down-rules.vue").then(m => m.default || m)
  },
  {
    name: "champd-up-slam-down-rules___de",
    path: "/de/champd-up-slam-down-rules",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/champd-up-slam-down-rules.vue").then(m => m.default || m)
  },
  {
    name: "champd-up-slam-down-rules___es",
    path: "/es/champd-up-slam-down-rules",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/champd-up-slam-down-rules.vue").then(m => m.default || m)
  },
  {
    name: "champd-up-slam-down-rules___es-XL",
    path: "/es-XL/champd-up-slam-down-rules",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/champd-up-slam-down-rules.vue").then(m => m.default || m)
  },
  {
    name: "champd-up-slam-down-rules___pt-BR",
    path: "/pt-BR/champd-up-slam-down-rules",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/champd-up-slam-down-rules.vue").then(m => m.default || m)
  },
  {
    name: "company___en",
    path: "/company",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/company.vue").then(m => m.default || m)
  },
  {
    name: "company___fr",
    path: "/fr/company",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/company.vue").then(m => m.default || m)
  },
  {
    name: "company___it",
    path: "/it/company",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/company.vue").then(m => m.default || m)
  },
  {
    name: "company___de",
    path: "/de/company",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/company.vue").then(m => m.default || m)
  },
  {
    name: "company___es",
    path: "/es/company",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/company.vue").then(m => m.default || m)
  },
  {
    name: "company___es-XL",
    path: "/es-XL/company",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/company.vue").then(m => m.default || m)
  },
  {
    name: "company___pt-BR",
    path: "/pt-BR/company",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/company.vue").then(m => m.default || m)
  },
  {
    name: "debug-cms___en",
    path: "/debug/cms",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/cms.vue").then(m => m.default || m)
  },
  {
    name: "debug-cms___fr",
    path: "/fr/debug/cms",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/cms.vue").then(m => m.default || m)
  },
  {
    name: "debug-cms___it",
    path: "/it/debug/cms",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/cms.vue").then(m => m.default || m)
  },
  {
    name: "debug-cms___de",
    path: "/de/debug/cms",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/cms.vue").then(m => m.default || m)
  },
  {
    name: "debug-cms___es",
    path: "/es/debug/cms",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/cms.vue").then(m => m.default || m)
  },
  {
    name: "debug-cms___es-XL",
    path: "/es-XL/debug/cms",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/cms.vue").then(m => m.default || m)
  },
  {
    name: "debug-cms___pt-BR",
    path: "/pt-BR/debug/cms",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/cms.vue").then(m => m.default || m)
  },
  {
    name: "debug-env___en",
    path: "/debug/env",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/env.vue").then(m => m.default || m)
  },
  {
    name: "debug-env___fr",
    path: "/fr/debug/env",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/env.vue").then(m => m.default || m)
  },
  {
    name: "debug-env___it",
    path: "/it/debug/env",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/env.vue").then(m => m.default || m)
  },
  {
    name: "debug-env___de",
    path: "/de/debug/env",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/env.vue").then(m => m.default || m)
  },
  {
    name: "debug-env___es",
    path: "/es/debug/env",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/env.vue").then(m => m.default || m)
  },
  {
    name: "debug-env___es-XL",
    path: "/es-XL/debug/env",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/env.vue").then(m => m.default || m)
  },
  {
    name: "debug-env___pt-BR",
    path: "/pt-BR/debug/env",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/env.vue").then(m => m.default || m)
  },
  {
    name: "docs-docpath___en",
    path: "/docs/:docpath(.*)*",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/[...doc-path].vue").then(m => m.default || m)
  },
  {
    name: "docs-docpath___fr",
    path: "/fr/docs/:docpath(.*)*",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/[...doc-path].vue").then(m => m.default || m)
  },
  {
    name: "docs-docpath___it",
    path: "/it/docs/:docpath(.*)*",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/[...doc-path].vue").then(m => m.default || m)
  },
  {
    name: "docs-docpath___de",
    path: "/de/docs/:docpath(.*)*",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/[...doc-path].vue").then(m => m.default || m)
  },
  {
    name: "docs-docpath___es",
    path: "/es/docs/:docpath(.*)*",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/[...doc-path].vue").then(m => m.default || m)
  },
  {
    name: "docs-docpath___es-XL",
    path: "/es-XL/docs/:docpath(.*)*",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/[...doc-path].vue").then(m => m.default || m)
  },
  {
    name: "docs-docpath___pt-BR",
    path: "/pt-BR/docs/:docpath(.*)*",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/[...doc-path].vue").then(m => m.default || m)
  },
  {
    name: "docs___en",
    path: "/docs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: "docs___fr",
    path: "/fr/docs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: "docs___it",
    path: "/it/docs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: "docs___de",
    path: "/de/docs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: "docs___es",
    path: "/es/docs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: "docs___es-XL",
    path: "/es-XL/docs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: "docs___pt-BR",
    path: "/pt-BR/docs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: "form___en",
    path: "/form",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/form.vue").then(m => m.default || m)
  },
  {
    name: "form___fr",
    path: "/fr/form",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/form.vue").then(m => m.default || m)
  },
  {
    name: "form___it",
    path: "/it/form",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/form.vue").then(m => m.default || m)
  },
  {
    name: "form___de",
    path: "/de/form",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/form.vue").then(m => m.default || m)
  },
  {
    name: "form___es",
    path: "/es/form",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/form.vue").then(m => m.default || m)
  },
  {
    name: "form___es-XL",
    path: "/es-XL/form",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/form.vue").then(m => m.default || m)
  },
  {
    name: "form___pt-BR",
    path: "/pt-BR/form",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/form.vue").then(m => m.default || m)
  },
  {
    name: "game-type-gametypeslug___en",
    path: "/game-type/:gametypeslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[gametype-slug].vue").then(m => m.default || m)
  },
  {
    name: "game-type-gametypeslug___fr",
    path: "/fr/game-type/:gametypeslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[gametype-slug].vue").then(m => m.default || m)
  },
  {
    name: "game-type-gametypeslug___it",
    path: "/it/game-type/:gametypeslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[gametype-slug].vue").then(m => m.default || m)
  },
  {
    name: "game-type-gametypeslug___de",
    path: "/de/game-type/:gametypeslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[gametype-slug].vue").then(m => m.default || m)
  },
  {
    name: "game-type-gametypeslug___es",
    path: "/es/game-type/:gametypeslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[gametype-slug].vue").then(m => m.default || m)
  },
  {
    name: "game-type-gametypeslug___es-XL",
    path: "/es-XL/game-type/:gametypeslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[gametype-slug].vue").then(m => m.default || m)
  },
  {
    name: "game-type-gametypeslug___pt-BR",
    path: "/pt-BR/game-type/:gametypeslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[gametype-slug].vue").then(m => m.default || m)
  },
  {
    name: "game-type___en",
    path: "/game-type",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/index.vue").then(m => m.default || m)
  },
  {
    name: "game-type___fr",
    path: "/fr/game-type",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/index.vue").then(m => m.default || m)
  },
  {
    name: "game-type___it",
    path: "/it/game-type",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/index.vue").then(m => m.default || m)
  },
  {
    name: "game-type___de",
    path: "/de/game-type",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/index.vue").then(m => m.default || m)
  },
  {
    name: "game-type___es",
    path: "/es/game-type",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/index.vue").then(m => m.default || m)
  },
  {
    name: "game-type___es-XL",
    path: "/es-XL/game-type",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/index.vue").then(m => m.default || m)
  },
  {
    name: "game-type___pt-BR",
    path: "/pt-BR/game-type",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/index.vue").then(m => m.default || m)
  },
  {
    name: "games-gameslug___en",
    path: "/games/:gameslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-gameslug___fr",
    path: "/fr/games/:gameslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-gameslug___it",
    path: "/it/games/:gameslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-gameslug___de",
    path: "/de/games/:gameslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-gameslug___es",
    path: "/es/games/:gameslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-gameslug___es-XL",
    path: "/es-XL/games/:gameslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-gameslug___pt-BR",
    path: "/pt-BR/games/:gameslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-bundles-bundleslug___en",
    path: "/games/bundles/:bundleslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-bundles-bundleslug___fr",
    path: "/fr/games/bundles/:bundleslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-bundles-bundleslug___it",
    path: "/it/games/bundles/:bundleslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-bundles-bundleslug___de",
    path: "/de/games/bundles/:bundleslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-bundles-bundleslug___es",
    path: "/es/games/bundles/:bundleslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-bundles-bundleslug___es-XL",
    path: "/es-XL/games/bundles/:bundleslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-bundles-bundleslug___pt-BR",
    path: "/pt-BR/games/bundles/:bundleslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-game-tags___en",
    path: "/games/game-tags",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/game-tags.vue").then(m => m.default || m)
  },
  {
    name: "games-game-tags___fr",
    path: "/fr/games/game-tags",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/game-tags.vue").then(m => m.default || m)
  },
  {
    name: "games-game-tags___it",
    path: "/it/games/game-tags",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/game-tags.vue").then(m => m.default || m)
  },
  {
    name: "games-game-tags___de",
    path: "/de/games/game-tags",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/game-tags.vue").then(m => m.default || m)
  },
  {
    name: "games-game-tags___es",
    path: "/es/games/game-tags",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/game-tags.vue").then(m => m.default || m)
  },
  {
    name: "games-game-tags___es-XL",
    path: "/es-XL/games/game-tags",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/game-tags.vue").then(m => m.default || m)
  },
  {
    name: "games-game-tags___pt-BR",
    path: "/pt-BR/games/game-tags",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/game-tags.vue").then(m => m.default || m)
  },
  {
    name: "games___en",
    path: "/games",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: "games___fr",
    path: "/fr/games",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: "games___it",
    path: "/it/games",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: "games___de",
    path: "/de/games",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: "games___es",
    path: "/es/games",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: "games___es-XL",
    path: "/es-XL/games",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: "games___pt-BR",
    path: "/pt-BR/games",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: "games-packs-packslug___en",
    path: "/games/packs/:packslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/[pack-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-packs-packslug___fr",
    path: "/fr/games/packs/:packslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/[pack-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-packs-packslug___it",
    path: "/it/games/packs/:packslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/[pack-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-packs-packslug___de",
    path: "/de/games/packs/:packslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/[pack-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-packs-packslug___es",
    path: "/es/games/packs/:packslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/[pack-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-packs-packslug___es-XL",
    path: "/es-XL/games/packs/:packslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/[pack-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-packs-packslug___pt-BR",
    path: "/pt-BR/games/packs/:packslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/[pack-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-packs___en",
    path: "/games/packs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/index.vue").then(m => m.default || m)
  },
  {
    name: "games-packs___fr",
    path: "/fr/games/packs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/index.vue").then(m => m.default || m)
  },
  {
    name: "games-packs___it",
    path: "/it/games/packs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/index.vue").then(m => m.default || m)
  },
  {
    name: "games-packs___de",
    path: "/de/games/packs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/index.vue").then(m => m.default || m)
  },
  {
    name: "games-packs___es",
    path: "/es/games/packs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/index.vue").then(m => m.default || m)
  },
  {
    name: "games-packs___es-XL",
    path: "/es-XL/games/packs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/index.vue").then(m => m.default || m)
  },
  {
    name: "games-packs___pt-BR",
    path: "/pt-BR/games/packs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/index.vue").then(m => m.default || m)
  },
  {
    name: "games-sale___en",
    path: "/games/sale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/sale/index.vue").then(m => m.default || m)
  },
  {
    name: "games-sale___fr",
    path: "/fr/games/sale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/sale/index.vue").then(m => m.default || m)
  },
  {
    name: "games-sale___it",
    path: "/it/games/sale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/sale/index.vue").then(m => m.default || m)
  },
  {
    name: "games-sale___de",
    path: "/de/games/sale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/sale/index.vue").then(m => m.default || m)
  },
  {
    name: "games-sale___es",
    path: "/es/games/sale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/sale/index.vue").then(m => m.default || m)
  },
  {
    name: "games-sale___es-XL",
    path: "/es-XL/games/sale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/sale/index.vue").then(m => m.default || m)
  },
  {
    name: "games-sale___pt-BR",
    path: "/pt-BR/games/sale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/sale/index.vue").then(m => m.default || m)
  },
  {
    name: "gift___en",
    path: "/gift",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: "gift___fr",
    path: "/fr/gift",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: "gift___it",
    path: "/it/gift",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: "gift___de",
    path: "/de/gift",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: "gift___es",
    path: "/es/gift",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: "gift___es-XL",
    path: "/es-XL/gift",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: "gift___pt-BR",
    path: "/pt-BR/gift",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: "how-to-play___en",
    path: "/how-to-play",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/how-to-play.vue").then(m => m.default || m)
  },
  {
    name: "how-to-play___fr",
    path: "/fr/how-to-play",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/how-to-play.vue").then(m => m.default || m)
  },
  {
    name: "how-to-play___it",
    path: "/it/how-to-play",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/how-to-play.vue").then(m => m.default || m)
  },
  {
    name: "how-to-play___de",
    path: "/de/how-to-play",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/how-to-play.vue").then(m => m.default || m)
  },
  {
    name: "how-to-play___es",
    path: "/es/how-to-play",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/how-to-play.vue").then(m => m.default || m)
  },
  {
    name: "how-to-play___es-XL",
    path: "/es-XL/how-to-play",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/how-to-play.vue").then(m => m.default || m)
  },
  {
    name: "how-to-play___pt-BR",
    path: "/pt-BR/how-to-play",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/how-to-play.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es-XL",
    path: "/es-XL",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pt-BR",
    path: "/pt-BR",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-at-work___en",
    path: "/jackbox-at-work",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-at-work.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-at-work___fr",
    path: "/fr/jackbox-at-work",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-at-work.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-at-work___it",
    path: "/it/jackbox-at-work",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-at-work.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-at-work___de",
    path: "/de/jackbox-at-work",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-at-work.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-at-work___es",
    path: "/es/jackbox-at-work",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-at-work.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-at-work___es-XL",
    path: "/es-XL/jackbox-at-work",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-at-work.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-at-work___pt-BR",
    path: "/pt-BR/jackbox-at-work",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-at-work.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-events___en",
    path: "/jackbox-for-events",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-events.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-events___fr",
    path: "/fr/jackbox-for-events",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-events.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-events___it",
    path: "/it/jackbox-for-events",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-events.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-events___de",
    path: "/de/jackbox-for-events",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-events.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-events___es",
    path: "/es/jackbox-for-events",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-events.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-events___es-XL",
    path: "/es-XL/jackbox-for-events",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-events.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-events___pt-BR",
    path: "/pt-BR/jackbox-for-events",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-events.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-families___en",
    path: "/jackbox-for-families",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-families.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-families___fr",
    path: "/fr/jackbox-for-families",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-families.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-families___it",
    path: "/it/jackbox-for-families",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-families.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-families___de",
    path: "/de/jackbox-for-families",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-families.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-families___es",
    path: "/es/jackbox-for-families",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-families.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-families___es-XL",
    path: "/es-XL/jackbox-for-families",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-families.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-families___pt-BR",
    path: "/pt-BR/jackbox-for-families",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-families.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-game-night___en",
    path: "/jackbox-for-game-night",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-game-night.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-game-night___fr",
    path: "/fr/jackbox-for-game-night",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-game-night.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-game-night___it",
    path: "/it/jackbox-for-game-night",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-game-night.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-game-night___de",
    path: "/de/jackbox-for-game-night",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-game-night.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-game-night___es",
    path: "/es/jackbox-for-game-night",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-game-night.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-game-night___es-XL",
    path: "/es-XL/jackbox-for-game-night",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-game-night.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-game-night___pt-BR",
    path: "/pt-BR/jackbox-for-game-night",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-game-night.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-streamers___en",
    path: "/jackbox-for-streamers",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-streamers.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-streamers___fr",
    path: "/fr/jackbox-for-streamers",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-streamers.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-streamers___it",
    path: "/it/jackbox-for-streamers",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-streamers.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-streamers___de",
    path: "/de/jackbox-for-streamers",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-streamers.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-streamers___es",
    path: "/es/jackbox-for-streamers",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-streamers.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-streamers___es-XL",
    path: "/es-XL/jackbox-for-streamers",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-streamers.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-streamers___pt-BR",
    path: "/pt-BR/jackbox-for-streamers",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-streamers.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-students___en",
    path: "/jackbox-for-students",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-students.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-students___fr",
    path: "/fr/jackbox-for-students",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-students.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-students___it",
    path: "/it/jackbox-for-students",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-students.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-students___de",
    path: "/de/jackbox-for-students",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-students.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-students___es",
    path: "/es/jackbox-for-students",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-students.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-students___es-XL",
    path: "/es-XL/jackbox-for-students",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-students.vue").then(m => m.default || m)
  },
  {
    name: "jackbox-for-students___pt-BR",
    path: "/pt-BR/jackbox-for-students",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-students.vue").then(m => m.default || m)
  },
  {
    name: "jobs___en",
    path: "/jobs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: "jobs___fr",
    path: "/fr/jobs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: "jobs___it",
    path: "/it/jobs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: "jobs___de",
    path: "/de/jobs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: "jobs___es",
    path: "/es/jobs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: "jobs___es-XL",
    path: "/es-XL/jobs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: "jobs___pt-BR",
    path: "/pt-BR/jobs",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/login",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___fr",
    path: "/fr/login",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___it",
    path: "/it/login",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___de",
    path: "/de/login",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___es",
    path: "/es/login",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___es-XL",
    path: "/es-XL/login",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___pt-BR",
    path: "/pt-BR/login",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "mysterious-teaser___en",
    path: "/mysterious-teaser",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/mysterious-teaser.vue").then(m => m.default || m)
  },
  {
    name: "mysterious-teaser___fr",
    path: "/fr/mysterious-teaser",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/mysterious-teaser.vue").then(m => m.default || m)
  },
  {
    name: "mysterious-teaser___it",
    path: "/it/mysterious-teaser",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/mysterious-teaser.vue").then(m => m.default || m)
  },
  {
    name: "mysterious-teaser___de",
    path: "/de/mysterious-teaser",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/mysterious-teaser.vue").then(m => m.default || m)
  },
  {
    name: "mysterious-teaser___es",
    path: "/es/mysterious-teaser",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/mysterious-teaser.vue").then(m => m.default || m)
  },
  {
    name: "mysterious-teaser___es-XL",
    path: "/es-XL/mysterious-teaser",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/mysterious-teaser.vue").then(m => m.default || m)
  },
  {
    name: "mysterious-teaser___pt-BR",
    path: "/pt-BR/mysterious-teaser",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/mysterious-teaser.vue").then(m => m.default || m)
  },
  {
    name: "play-game___en",
    path: "/play/:game()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/[game].vue").then(m => m.default || m)
  },
  {
    name: "play-game___fr",
    path: "/fr/play/:game()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/[game].vue").then(m => m.default || m)
  },
  {
    name: "play-game___it",
    path: "/it/play/:game()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/[game].vue").then(m => m.default || m)
  },
  {
    name: "play-game___de",
    path: "/de/play/:game()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/[game].vue").then(m => m.default || m)
  },
  {
    name: "play-game___es",
    path: "/es/play/:game()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/[game].vue").then(m => m.default || m)
  },
  {
    name: "play-game___es-XL",
    path: "/es-XL/play/:game()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/[game].vue").then(m => m.default || m)
  },
  {
    name: "play-game___pt-BR",
    path: "/pt-BR/play/:game()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/[game].vue").then(m => m.default || m)
  },
  {
    name: "play___en",
    path: "/play",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/index.vue").then(m => m.default || m)
  },
  {
    name: "play___fr",
    path: "/fr/play",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/index.vue").then(m => m.default || m)
  },
  {
    name: "play___it",
    path: "/it/play",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/index.vue").then(m => m.default || m)
  },
  {
    name: "play___de",
    path: "/de/play",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/index.vue").then(m => m.default || m)
  },
  {
    name: "play___es",
    path: "/es/play",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/index.vue").then(m => m.default || m)
  },
  {
    name: "play___es-XL",
    path: "/es-XL/play",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/index.vue").then(m => m.default || m)
  },
  {
    name: "play___pt-BR",
    path: "/pt-BR/play",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/index.vue").then(m => m.default || m)
  },
  {
    name: "preference-center___en",
    path: "/preference-center",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/preference-center.vue").then(m => m.default || m)
  },
  {
    name: "preference-center___fr",
    path: "/fr/preference-center",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/preference-center.vue").then(m => m.default || m)
  },
  {
    name: "preference-center___it",
    path: "/it/preference-center",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/preference-center.vue").then(m => m.default || m)
  },
  {
    name: "preference-center___de",
    path: "/de/preference-center",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/preference-center.vue").then(m => m.default || m)
  },
  {
    name: "preference-center___es",
    path: "/es/preference-center",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/preference-center.vue").then(m => m.default || m)
  },
  {
    name: "preference-center___es-XL",
    path: "/es-XL/preference-center",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/preference-center.vue").then(m => m.default || m)
  },
  {
    name: "preference-center___pt-BR",
    path: "/pt-BR/preference-center",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/preference-center.vue").then(m => m.default || m)
  },
  {
    name: "presskit___en",
    path: "/presskit",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/presskit.vue").then(m => m.default || m)
  },
  {
    name: "presskit___fr",
    path: "/fr/presskit",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/presskit.vue").then(m => m.default || m)
  },
  {
    name: "presskit___it",
    path: "/it/presskit",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/presskit.vue").then(m => m.default || m)
  },
  {
    name: "presskit___de",
    path: "/de/presskit",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/presskit.vue").then(m => m.default || m)
  },
  {
    name: "presskit___es",
    path: "/es/presskit",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/presskit.vue").then(m => m.default || m)
  },
  {
    name: "presskit___es-XL",
    path: "/es-XL/presskit",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/presskit.vue").then(m => m.default || m)
  },
  {
    name: "presskit___pt-BR",
    path: "/pt-BR/presskit",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/presskit.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___fr",
    path: "/fr/privacy-policy",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___it",
    path: "/it/privacy-policy",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___de",
    path: "/de/privacy-policy",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___es",
    path: "/es/privacy-policy",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___es-XL",
    path: "/es-XL/privacy-policy",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___pt-BR",
    path: "/pt-BR/privacy-policy",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "shop___en",
    path: "/shop",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop___fr",
    path: "/fr/shop",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop___it",
    path: "/it/shop",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop___de",
    path: "/de/shop",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop___es",
    path: "/es/shop",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop___es-XL",
    path: "/es-XL/shop",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop___pt-BR",
    path: "/pt-BR/shop",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-merch-merchslug___en",
    path: "/shop/merch/:merchslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/merch/[merch-slug].vue").then(m => m.default || m)
  },
  {
    name: "shop-merch-merchslug___fr",
    path: "/fr/shop/merch/:merchslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/merch/[merch-slug].vue").then(m => m.default || m)
  },
  {
    name: "shop-merch-merchslug___it",
    path: "/it/shop/merch/:merchslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/merch/[merch-slug].vue").then(m => m.default || m)
  },
  {
    name: "shop-merch-merchslug___de",
    path: "/de/shop/merch/:merchslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/merch/[merch-slug].vue").then(m => m.default || m)
  },
  {
    name: "shop-merch-merchslug___es",
    path: "/es/shop/merch/:merchslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/merch/[merch-slug].vue").then(m => m.default || m)
  },
  {
    name: "shop-merch-merchslug___es-XL",
    path: "/es-XL/shop/merch/:merchslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/merch/[merch-slug].vue").then(m => m.default || m)
  },
  {
    name: "shop-merch-merchslug___pt-BR",
    path: "/pt-BR/shop/merch/:merchslug()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/merch/[merch-slug].vue").then(m => m.default || m)
  },
  {
    name: "support___en",
    path: "/support",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "support___fr",
    path: "/fr/support",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "support___it",
    path: "/it/support",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "support___de",
    path: "/de/support",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "support___es",
    path: "/es/support",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "support___es-XL",
    path: "/es-XL/support",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "support___pt-BR",
    path: "/pt-BR/support",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___en",
    path: "/terms-of-service",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___fr",
    path: "/fr/terms-of-service",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___it",
    path: "/it/terms-of-service",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___de",
    path: "/de/terms-of-service",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___es",
    path: "/es/terms-of-service",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___es-XL",
    path: "/es-XL/terms-of-service",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___pt-BR",
    path: "/pt-BR/terms-of-service",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "the-jackbox-megapicker___en",
    path: "/the-jackbox-megapicker",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: "the-jackbox-megapicker___fr",
    path: "/fr/the-jackbox-megapicker",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: "the-jackbox-megapicker___it",
    path: "/it/the-jackbox-megapicker",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: "the-jackbox-megapicker___de",
    path: "/de/the-jackbox-megapicker",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: "the-jackbox-megapicker___es",
    path: "/es/the-jackbox-megapicker",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: "the-jackbox-megapicker___es-XL",
    path: "/es-XL/the-jackbox-megapicker",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: "the-jackbox-megapicker___pt-BR",
    path: "/pt-BR/the-jackbox-megapicker",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: "token___en",
    path: "/token",
    meta: tokenAd3wHcwDQmMeta || {},
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/token.vue").then(m => m.default || m)
  },
  {
    name: "token___fr",
    path: "/fr/token",
    meta: tokenAd3wHcwDQmMeta || {},
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/token.vue").then(m => m.default || m)
  },
  {
    name: "token___it",
    path: "/it/token",
    meta: tokenAd3wHcwDQmMeta || {},
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/token.vue").then(m => m.default || m)
  },
  {
    name: "token___de",
    path: "/de/token",
    meta: tokenAd3wHcwDQmMeta || {},
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/token.vue").then(m => m.default || m)
  },
  {
    name: "token___es",
    path: "/es/token",
    meta: tokenAd3wHcwDQmMeta || {},
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/token.vue").then(m => m.default || m)
  },
  {
    name: "token___es-XL",
    path: "/es-XL/token",
    meta: tokenAd3wHcwDQmMeta || {},
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/token.vue").then(m => m.default || m)
  },
  {
    name: "token___pt-BR",
    path: "/pt-BR/token",
    meta: tokenAd3wHcwDQmMeta || {},
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/token.vue").then(m => m.default || m)
  },
  {
    name: "try-guys___en",
    path: "/try-guys",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/try-guys.vue").then(m => m.default || m)
  },
  {
    name: "try-guys___fr",
    path: "/fr/try-guys",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/try-guys.vue").then(m => m.default || m)
  },
  {
    name: "try-guys___it",
    path: "/it/try-guys",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/try-guys.vue").then(m => m.default || m)
  },
  {
    name: "try-guys___de",
    path: "/de/try-guys",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/try-guys.vue").then(m => m.default || m)
  },
  {
    name: "try-guys___es",
    path: "/es/try-guys",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/try-guys.vue").then(m => m.default || m)
  },
  {
    name: "try-guys___es-XL",
    path: "/es-XL/try-guys",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/try-guys.vue").then(m => m.default || m)
  },
  {
    name: "try-guys___pt-BR",
    path: "/pt-BR/try-guys",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/try-guys.vue").then(m => m.default || m)
  },
  {
    name: "tvcode___en",
    path: "/tvcode",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/tvcode.vue").then(m => m.default || m)
  },
  {
    name: "tvcode___fr",
    path: "/fr/tvcode",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/tvcode.vue").then(m => m.default || m)
  },
  {
    name: "tvcode___it",
    path: "/it/tvcode",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/tvcode.vue").then(m => m.default || m)
  },
  {
    name: "tvcode___de",
    path: "/de/tvcode",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/tvcode.vue").then(m => m.default || m)
  },
  {
    name: "tvcode___es",
    path: "/es/tvcode",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/tvcode.vue").then(m => m.default || m)
  },
  {
    name: "tvcode___es-XL",
    path: "/es-XL/tvcode",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/tvcode.vue").then(m => m.default || m)
  },
  {
    name: "tvcode___pt-BR",
    path: "/pt-BR/tvcode",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/tvcode.vue").then(m => m.default || m)
  },
  {
    name: "user-forgotpassword-email___en",
    path: "/user/forgotpassword/:email()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword/[email].vue").then(m => m.default || m)
  },
  {
    name: "user-forgotpassword-email___fr",
    path: "/fr/user/forgotpassword/:email()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword/[email].vue").then(m => m.default || m)
  },
  {
    name: "user-forgotpassword-email___it",
    path: "/it/user/forgotpassword/:email()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword/[email].vue").then(m => m.default || m)
  },
  {
    name: "user-forgotpassword-email___de",
    path: "/de/user/forgotpassword/:email()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword/[email].vue").then(m => m.default || m)
  },
  {
    name: "user-forgotpassword-email___es",
    path: "/es/user/forgotpassword/:email()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword/[email].vue").then(m => m.default || m)
  },
  {
    name: "user-forgotpassword-email___es-XL",
    path: "/es-XL/user/forgotpassword/:email()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword/[email].vue").then(m => m.default || m)
  },
  {
    name: "user-forgotpassword-email___pt-BR",
    path: "/pt-BR/user/forgotpassword/:email()",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword/[email].vue").then(m => m.default || m)
  },
  {
    name: "user-forgotpassword___en",
    path: "/user/forgotpassword",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword/index.vue").then(m => m.default || m)
  },
  {
    name: "user-forgotpassword___fr",
    path: "/fr/user/forgotpassword",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword/index.vue").then(m => m.default || m)
  },
  {
    name: "user-forgotpassword___it",
    path: "/it/user/forgotpassword",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword/index.vue").then(m => m.default || m)
  },
  {
    name: "user-forgotpassword___de",
    path: "/de/user/forgotpassword",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword/index.vue").then(m => m.default || m)
  },
  {
    name: "user-forgotpassword___es",
    path: "/es/user/forgotpassword",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword/index.vue").then(m => m.default || m)
  },
  {
    name: "user-forgotpassword___es-XL",
    path: "/es-XL/user/forgotpassword",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword/index.vue").then(m => m.default || m)
  },
  {
    name: "user-forgotpassword___pt-BR",
    path: "/pt-BR/user/forgotpassword",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword/index.vue").then(m => m.default || m)
  },
  {
    name: "user-profile___en",
    path: "/user/profile",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: "user-profile___fr",
    path: "/fr/user/profile",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: "user-profile___it",
    path: "/it/user/profile",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: "user-profile___de",
    path: "/de/user/profile",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: "user-profile___es",
    path: "/es/user/profile",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: "user-profile___es-XL",
    path: "/es-XL/user/profile",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: "user-profile___pt-BR",
    path: "/pt-BR/user/profile",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: "user-verify___en",
    path: "/user/verify",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/verify.vue").then(m => m.default || m)
  },
  {
    name: "user-verify___fr",
    path: "/fr/user/verify",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/verify.vue").then(m => m.default || m)
  },
  {
    name: "user-verify___it",
    path: "/it/user/verify",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/verify.vue").then(m => m.default || m)
  },
  {
    name: "user-verify___de",
    path: "/de/user/verify",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/verify.vue").then(m => m.default || m)
  },
  {
    name: "user-verify___es",
    path: "/es/user/verify",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/verify.vue").then(m => m.default || m)
  },
  {
    name: "user-verify___es-XL",
    path: "/es-XL/user/verify",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/verify.vue").then(m => m.default || m)
  },
  {
    name: "user-verify___pt-BR",
    path: "/pt-BR/user/verify",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/verify.vue").then(m => m.default || m)
  },
  {
    name: "xperion___en",
    path: "/xperion",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/xperion.vue").then(m => m.default || m)
  },
  {
    name: "xperion___fr",
    path: "/fr/xperion",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/xperion.vue").then(m => m.default || m)
  },
  {
    name: "xperion___it",
    path: "/it/xperion",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/xperion.vue").then(m => m.default || m)
  },
  {
    name: "xperion___de",
    path: "/de/xperion",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/xperion.vue").then(m => m.default || m)
  },
  {
    name: "xperion___es",
    path: "/es/xperion",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/xperion.vue").then(m => m.default || m)
  },
  {
    name: "xperion___es-XL",
    path: "/es-XL/xperion",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/xperion.vue").then(m => m.default || m)
  },
  {
    name: "xperion___pt-BR",
    path: "/pt-BR/xperion",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/xperion.vue").then(m => m.default || m)
  },
  {
    name: "games-bundles-bundleslug___en",
    path: "/games/bundles/:bundleslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-bundles-bundleslug___fr",
    path: "/fr/games/bundles/:bundleslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-bundles-bundleslug___it",
    path: "/it/games/bundles/:bundleslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-bundles-bundleslug___de",
    path: "/de/games/bundles/:bundleslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-bundles-bundleslug___es",
    path: "/es/games/bundles/:bundleslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-bundles-bundleslug___es-XL",
    path: "/es-XL/games/bundles/:bundleslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-bundles-bundleslug___pt-BR",
    path: "/pt-BR/games/bundles/:bundleslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-packs-packslug-gameslug___en",
    path: "/games/:packslug/:gameslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-packs-packslug-gameslug___fr",
    path: "/fr/games/:packslug/:gameslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-packs-packslug-gameslug___it",
    path: "/it/games/:packslug/:gameslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-packs-packslug-gameslug___de",
    path: "/de/games/:packslug/:gameslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-packs-packslug-gameslug___es",
    path: "/es/games/:packslug/:gameslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-packs-packslug-gameslug___es-XL",
    path: "/es-XL/games/:packslug/:gameslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: "games-packs-packslug-gameslug___pt-BR",
    path: "/pt-BR/games/:packslug/:gameslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: "game-type-gametypeslug___en",
    path: "/game-type/:gametypeslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[gametype-slug].vue").then(m => m.default || m)
  },
  {
    name: "game-type-gametypeslug___fr",
    path: "/fr/game-type/:gametypeslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[gametype-slug].vue").then(m => m.default || m)
  },
  {
    name: "game-type-gametypeslug___it",
    path: "/it/game-type/:gametypeslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[gametype-slug].vue").then(m => m.default || m)
  },
  {
    name: "game-type-gametypeslug___de",
    path: "/de/game-type/:gametypeslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[gametype-slug].vue").then(m => m.default || m)
  },
  {
    name: "game-type-gametypeslug___es",
    path: "/es/game-type/:gametypeslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[gametype-slug].vue").then(m => m.default || m)
  },
  {
    name: "game-type-gametypeslug___es-XL",
    path: "/es-XL/game-type/:gametypeslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[gametype-slug].vue").then(m => m.default || m)
  },
  {
    name: "game-type-gametypeslug___pt-BR",
    path: "/pt-BR/game-type/:gametypeslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[gametype-slug].vue").then(m => m.default || m)
  },
  {
    name: "sale___en",
    path: "/games/sale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/sale/index.vue").then(m => m.default || m)
  },
  {
    name: "sale___fr",
    path: "/fr/games/sale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/sale/index.vue").then(m => m.default || m)
  },
  {
    name: "sale___it",
    path: "/it/games/sale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/sale/index.vue").then(m => m.default || m)
  },
  {
    name: "sale___de",
    path: "/de/games/sale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/sale/index.vue").then(m => m.default || m)
  },
  {
    name: "sale___es",
    path: "/es/games/sale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/sale/index.vue").then(m => m.default || m)
  },
  {
    name: "sale___es-XL",
    path: "/es-XL/games/sale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/sale/index.vue").then(m => m.default || m)
  },
  {
    name: "sale___pt-BR",
    path: "/pt-BR/games/sale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/sale/index.vue").then(m => m.default || m)
  },
  {
    name: "gift-locale___en",
    path: "/gift:locale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: "gift-locale___fr",
    path: "/fr/gift:locale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: "gift-locale___it",
    path: "/it/gift:locale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: "gift-locale___de",
    path: "/de/gift:locale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: "gift-locale___es",
    path: "/es/gift:locale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: "gift-locale___es-XL",
    path: "/es-XL/gift:locale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: "gift-locale___pt-BR",
    path: "/pt-BR/gift:locale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: "the-jackbox-megapicker-locale___en",
    path: "/the-jackbox-megapicker:locale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: "the-jackbox-megapicker-locale___fr",
    path: "/fr/the-jackbox-megapicker:locale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: "the-jackbox-megapicker-locale___it",
    path: "/it/the-jackbox-megapicker:locale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: "the-jackbox-megapicker-locale___de",
    path: "/de/the-jackbox-megapicker:locale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: "the-jackbox-megapicker-locale___es",
    path: "/es/the-jackbox-megapicker:locale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: "the-jackbox-megapicker-locale___es-XL",
    path: "/es-XL/the-jackbox-megapicker:locale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: "the-jackbox-megapicker-locale___pt-BR",
    path: "/pt-BR/the-jackbox-megapicker:locale",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: "login-step___en",
    path: "/login/:step",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login-step___fr",
    path: "/fr/login/:step",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login-step___it",
    path: "/it/login/:step",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login-step___de",
    path: "/de/login/:step",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login-step___es",
    path: "/es/login/:step",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login-step___es-XL",
    path: "/es-XL/login/:step",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login-step___pt-BR",
    path: "/pt-BR/login/:step",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "shop-tab___en",
    path: "/shop/:tab?",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-tab___fr",
    path: "/fr/shop/:tab?",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-tab___it",
    path: "/it/shop/:tab?",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-tab___de",
    path: "/de/shop/:tab?",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-tab___es",
    path: "/es/shop/:tab?",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-tab___es-XL",
    path: "/es-XL/shop/:tab?",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-tab___pt-BR",
    path: "/pt-BR/shop/:tab?",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "game-tags___en",
    path: "/games/tags",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/game-tags.vue").then(m => m.default || m)
  },
  {
    name: "game-tags___fr",
    path: "/fr/games/tags",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/game-tags.vue").then(m => m.default || m)
  },
  {
    name: "game-tags___it",
    path: "/it/games/tags",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/game-tags.vue").then(m => m.default || m)
  },
  {
    name: "game-tags___de",
    path: "/de/games/tags",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/game-tags.vue").then(m => m.default || m)
  },
  {
    name: "game-tags___es",
    path: "/es/games/tags",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/game-tags.vue").then(m => m.default || m)
  },
  {
    name: "game-tags___es-XL",
    path: "/es-XL/games/tags",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/game-tags.vue").then(m => m.default || m)
  },
  {
    name: "game-tags___pt-BR",
    path: "/pt-BR/games/tags",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/game-tags.vue").then(m => m.default || m)
  },
  {
    name: "blog-year-yearslug___en",
    path: "/blog/year/:yearslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/[year-slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-year-yearslug___fr",
    path: "/fr/blog/year/:yearslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/[year-slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-year-yearslug___it",
    path: "/it/blog/year/:yearslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/[year-slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-year-yearslug___de",
    path: "/de/blog/year/:yearslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/[year-slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-year-yearslug___es",
    path: "/es/blog/year/:yearslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/[year-slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-year-yearslug___es-XL",
    path: "/es-XL/blog/year/:yearslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/[year-slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-year-yearslug___pt-BR",
    path: "/pt-BR/blog/year/:yearslug",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/year/[year-slug].vue").then(m => m.default || m)
  },
  {
    name: "tryguys___en",
    path: "/tryguys",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/try-guys.vue").then(m => m.default || m)
  },
  {
    name: "tryguys___fr",
    path: "/fr/tryguys",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/try-guys.vue").then(m => m.default || m)
  },
  {
    name: "tryguys___it",
    path: "/it/tryguys",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/try-guys.vue").then(m => m.default || m)
  },
  {
    name: "tryguys___de",
    path: "/de/tryguys",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/try-guys.vue").then(m => m.default || m)
  },
  {
    name: "tryguys___es",
    path: "/es/tryguys",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/try-guys.vue").then(m => m.default || m)
  },
  {
    name: "tryguys___es-XL",
    path: "/es-XL/tryguys",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/try-guys.vue").then(m => m.default || m)
  },
  {
    name: "tryguys___pt-BR",
    path: "/pt-BR/tryguys",
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/try-guys.vue").then(m => m.default || m)
  },
  {
    name: component_45stubYhFd71CncqMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubYhFd71Cncq
  },
  {
    name: component_45stubYhFd71CncqMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubYhFd71Cncq
  },
  {
    name: component_45stubYhFd71CncqMeta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stubYhFd71Cncq
  },
  {
    name: component_45stubYhFd71CncqMeta?.name,
    path: "/fr-FR-sitemap.xml",
    component: component_45stubYhFd71Cncq
  },
  {
    name: component_45stubYhFd71CncqMeta?.name,
    path: "/it-IT-sitemap.xml",
    component: component_45stubYhFd71Cncq
  },
  {
    name: component_45stubYhFd71CncqMeta?.name,
    path: "/de-DE-sitemap.xml",
    component: component_45stubYhFd71Cncq
  },
  {
    name: component_45stubYhFd71CncqMeta?.name,
    path: "/es-ES-sitemap.xml",
    component: component_45stubYhFd71Cncq
  },
  {
    name: component_45stubYhFd71CncqMeta?.name,
    path: "/es-XL-sitemap.xml",
    component: component_45stubYhFd71Cncq
  },
  {
    name: component_45stubYhFd71CncqMeta?.name,
    path: "/pt-BR-sitemap.xml",
    component: component_45stubYhFd71Cncq
  }
]