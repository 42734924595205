import { JBGApi } from './api'

export class AuthService extends JBGApi {
    private authUrl: string
    private clientId: string

    constructor(options: AuthService.Options) {
        super(options.binjpipeUrl)
        if (!options.authUrl.startsWith('http')) {
            options.authUrl = `https://${options.authUrl}`
        }
        this.authUrl = options.authUrl
        this.clientId = options.clientId
    }

    // login generates tokens for CSRF and PKCE and redirects to the auth server.
    public async login(provider: AuthService.Providers) {
        const state = await this.generateNonce() // CSRF
        const codeVerifier = await this.generateNonce() // PKCE
        sessionStorage.setItem(`codeVerifier-${state}`, codeVerifier)

        const shaVerify = await this.sha256(codeVerifier)
        const challenge = this.base64URLEncode(shaVerify)

        const localePath = useLocalePath()
        const redirectUri = window.location.origin + localePath('/token')

        window.location.href = `${this.authUrl}/authorize?response_type=code&identity_provider=${provider.toString()}&client_id=${this.clientId}&redirect_uri=${redirectUri}&state=${state}&code_challenge_method=S256&code_challenge=${challenge}`
    }

    // logout revokes the token and expires current set cookies.
    public async logout(id: number): Promise<Response> {
        const route = 'auth/logout'
        const options = this.buildOptions('POST', {
            id
        })

        return this.callAPI(route, options)
    }

    // signin via email/pwd for existing users
    public async emailSignin(email: string, password: string): Promise<Response> {
        const route = 'auth/signin'
        const options = this.buildOptions('POST', {
            email,
            password
        })

        return this.callAPI(route, options)
    }

    // exchangeCode sends the auth code to the backend where it will be exchanged for access/refresh tokens.
    // these tokens get set as http only cookies from the backend.
    public async exchangeCode(code: string, verify: string): Promise<Response> {
        const route = 'auth/code'
        const options = this.buildOptions('POST', {
            code,
            clientId: this.clientId,
            codeVerifier: verify,
            redirectUrl: window.location.href
        })

        return this.callAPI(route, options)
    }

    public async consumeDeviceCode(code: string): Promise<Response> {
        const route = 'auth/device/verify'
        const options = this.buildOptions('POST', {
            code
        })
        return this.callAPI(route, options)
    }

    public async forgotPassword(email: string): Promise<Response> {
        const route = 'auth/forgotpassword'
        const options = this.buildOptions('POST', {
            email
        })

        return this.callAPI(route, options)
    }

    public async confirmForgotPassword(email: string, code: string, password: string): Promise<Response> {
        const route = 'auth/forgotpassword/confirm'
        const options = this.buildOptions('POST', {
            email,
            code,
            password
        })

        return this.callAPI(route, options)
    }

    public async refreshToken(): Promise<Response> {
        const route = 'auth/refresh'
        const options = this.buildOptions('POST', {})

        return this.callAPI(route, options)
    }

    private async sha256(str: string): Promise<ArrayBuffer> {
        const sha = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(str))
        return sha
    }

    private async generateNonce() {
        const hash = await this.sha256(crypto.getRandomValues(new Uint32Array(4)).toString())
        // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
        const hashArray = Array.from(new Uint8Array(hash))
        return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
    }

    private base64URLEncode(str: ArrayBuffer): string {
        const arr = new Uint8Array(str)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return btoa(String.fromCharCode.apply(null, [...arr]))
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=+$/, '')
    }
}

export namespace AuthService {
    export interface Options {
        authUrl: string
        binjpipeUrl: string
        clientId: string
    }

    export const enum Providers {
        GOOGLE = 'Google'
    }
}
